<script setup lang="ts">
  import {computed} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpButtonIcon from '~/components/cp/CpButtonIcon/CpButtonIcon.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import type {CpButtonIconKind} from '@/types';
  import type {ICpTypeWithModifier} from '~/composables/useCpType';

  const props = withDefaults(defineProps<{
    title: string,
    subtitle?: string,
    type: 'h1'|'h2'|'h3'|'h1-fake'|'h3-fake'|'highlight-bold',
    icon?: string,
    button?: CpButtonIconKind,
    critical?: boolean,
    showTopBorder?: boolean,
    inline?: boolean,
    variant?: ICpTextVariants,
    color?: ICpTypeWithModifier
    af?: boolean
    afTitleUppercase?: boolean
    bgColor?: string
  }>(), {
    inline: false,
    button: undefined,
    icon: undefined,
    subtitle: undefined,
    color: 'neutral-dark',
    variant: undefined,
    af: false,
    afTitleUppercase: true,
    bgColor: undefined,
  });

  const varianCpText = computed<ICpTextVariants>(() => {
    return props.type === 'highlight-bold' ? 'highlight-bold' : `heading-${props.type}`;
  });

  defineEmits({
    headerAction: null,
  });
  const {bm, e} = useCpBem('cp-section-header');
</script>

<template>
    <div :class="bm({ addBorder: showTopBorder, af})">
        <div :class="e('titleBox')">
            <div v-if="inline" :class="e('inline-box')">
                <CpText
                    :variant="varianCpText"
                    :type="critical ? 'critical-dark' : color"
                >
                    {{ title }}
                </CpText>
                <CpText :variant="type==='h2' ? 'heading-h2' : 'heading-h3'" type="neutral">
                    {{ subtitle }}
                </CpText>
            </div>
            <CpText
                v-else
                :variant="varianCpText"
                :type="critical ? 'critical-dark' : af ? 'white' : color"
            >
                {{ af && afTitleUppercase ? title.toUpperCase() : title }}
            </CpText>
            <CpText v-if="subtitle && !inline" variant="subheading" type="neutral-dark">
                {{ subtitle }}
            </CpText>
        </div>
        <div :class="e('actions')" data-testid="header-button-icon-container">
            <CpIconStandard v-if="icon && button === undefined" :icon="icon!" type="primary" />
            <CpButtonIcon
                v-else-if="button"
                :class="e('button')"
                :icon="button"
                type="primary"
                data-testid="header-button-icon"
                @click="$emit('headerAction')"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
  .cp-section-header {
    display: flex;
    padding: 16px;
    gap: $cp-gutter-small;
    justify-content: space-between;
    align-items: center;
    border-bottom: $cp-border-standard;
    width: 100%;
    background-color: v-bind(bgColor) !important;
    &--add-border {
      border-top: $cp-border-standard;
    }
    &--af {
      border-bottom: 1px solid var(--color-neutral-light);
    }
    &__title-box {
      display: flex;
      flex-direction: column;
    }
    &__inline-box {
      display: flex;
      align-items: baseline;
      gap: $cp-gutter-xsmall;
    }
  }
</style>
